import { Paper, Typography, Button, Container, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/es";
import { send } from "emailjs-com";
import ErrorSnackBar from "../ErrorSnackBar";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
  paper: {
    padding: 16,
  },
});

export const Step3 = ({ personalInfo, consultInfo, back, next }) => {
  const classes = useStyles();

  moment.locale("es");
  const [openError, setOpenError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const onSubmit = () => {
    setLoading(true);
    var toSend = {
      ...personalInfo,
      ...consultInfo,
      dateBirthF: moment(personalInfo.dateBirth).format("LL"),
      dateConsultF: moment(consultInfo.date).format("LL"),
      timeConsultF: moment(consultInfo.date).format("LT"),
    };
    send(
      "laboratorio_clifford",
      "template_jmff7xn",
      toSend,
      "user_WF7uK3zWZOeQ257GHSfGe"
    ).then(
      () => {
        next();
      },
      () => {
        setOpenError(true);
        setLoading(false);
      }
    );
  };
  return (
    <div>
      <Typography variant="h4" align="center" color="primary" gutterBottom>
        Confirmar Datos
      </Typography>
      <Container>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Container>
                <Typography
                  variant="h6"
                  align="center"
                  color="primary"
                  className={classes.bold}
                >
                  Datos Personales
                </Typography>
                {personalInfo.name && (
                  <Typography variant="body1">
                    <b>Nombre(s): </b>
                    {personalInfo.name}
                  </Typography>
                )}
                {personalInfo.lastName && (
                  <Typography variant="body1">
                    <b>Apellido: </b>
                    {personalInfo.lastName}
                  </Typography>
                )}
                {personalInfo.number && (
                  <Typography variant="body1">
                    <b>Numero de Teléfono: </b>
                    {personalInfo.number}
                  </Typography>
                )}
                {personalInfo.email && (
                  <Typography variant="body1">
                    <b>Correo Electrónico: </b>
                    {personalInfo.email}
                  </Typography>
                )}
                {personalInfo.addres && (
                  <Typography variant="body1">
                    <b>Dirección: </b>
                    {personalInfo.addres}
                  </Typography>
                )}
                {personalInfo.dateBirth && (
                  <Typography variant="body1">
                    <b>Fecha de Nacimiento: </b>
                    {moment(personalInfo.dateBirth).format("LL")}
                  </Typography>
                )}
                {personalInfo.gender && (
                  <Typography variant="body1">
                    <b>Género: </b>
                    {personalInfo.gender === "male" ? "Masculino" : "Femenino"}
                  </Typography>
                )}
              </Container>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Container>
                <Typography
                  variant="h6"
                  align="center"
                  color="primary"
                  className={classes.bold}
                >
                  Consulta
                </Typography>
                {consultInfo.date && (
                  <Typography variant="body1">
                    <b>Fecha de Consulta: </b>
                    {moment(consultInfo.date).format("LL")}
                  </Typography>
                )}
                {consultInfo.date && (
                  <Typography variant="body1">
                    <b>Hora de Consulta: </b>
                    {moment(consultInfo.date).format("LT")}
                  </Typography>
                )}
                {consultInfo.note && (
                  <Typography variant="body1">
                    <b>Nota para laboratorio: </b>
                    {consultInfo.note}
                  </Typography>
                )}
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={back}>
              Atras
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </div>
      <ErrorSnackBar
        open={openError}
        mesage={"Error al mandar la solicitud, intentelo mas tarde."}
        close={handleCloseError}
      />
    </div>
  );
};
