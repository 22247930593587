import avatar2 from "../../assets/avatar2.png";
import avatar3 from "../../assets/avatar3.png";

export const Team = [
  {
    name: "Clifford Vargas",
    photo: avatar3,
    position: "Bioquimico Farmaceutico",
    diplomado: "Diplomado en Bacteriologia Clinica",
  },
  {
    name: "Paola Escalera",
    photo: avatar2,
    position: "Bioquimico Farmaceutico",
    diplomado: "Diplomado en Inmunología Celular y Molecular",

  }
];
