import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavBar } from "../layout/NavBar";
import { Footer } from "../layout/Footer";
import { HomePage } from "../pages/HomePage";
import { AboutUsPage } from "../pages/AboutUsPage";
import { LaboratoryExamPage } from "../pages/LaboratoryExamPage";
import { HomeConsultationPage } from "../pages/HomeConsultationPage";
import { ContactPage } from "../pages/ContactPage";
import ScrollToTop from "./ScrollToTop";
import ResultsPage from "../pages/ResultsPage";

const AppRoutes = () => (
  <Router>
    <NavBar />
    <div>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/quienes-somos">
          <AboutUsPage />
        </Route>
        <Route path="/examenes-laboratorio">
          <LaboratoryExamPage />
        </Route>
        <Route path="/consulta-domicilio">
          <HomeConsultationPage />
        </Route>
        <Route path="/resultados">
          <ResultsPage />
        </Route>
        <Route path="/contacto">
          <ContactPage />
        </Route>
        <Route component={() => <h2>ERROR 404</h2>} />
      </Switch>
    </div>
    <Footer />
  </Router>
);

export default AppRoutes;
