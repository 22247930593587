import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import imgCompromiso from "../../assets/handshake.png";
import imgResponsibility from "../../assets/responsibility.png";
import imgTecnology from "../../assets/tecnology.png";
import valuesImg from "../../assets/team.png";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: "#EEEEEE",
    paddingInline: "8vw",
    paddingBlock: "3vw",
    marginTop: "30px",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    "@media (max-width: 900px)": {
      fontSize: "24px",
      paddingRight: 0,
    },
  },
  subtitle: {
    textAlign: "center",
    marginBottom: "30px",
  },
  txtValue: {
    display: "table-cell",
    fontWeight: "bold",
  },
  img: {
    float: "right",
    filter: "invert(100%) drop-shadow(4px 4px 4px #383838)",
  },
  imgValues: {
    width: "80%",
    borderRadius: "10px",
  },
  spamImage: {
    textAlign: "center",
    display: "block",
  },
}));

export function ItemValue({ classes, img, text }) {
  return (
    <Grid item style={{ display: "flex" }}>
      <Grid justify={"center"} alignItems={"center"} container spacing={3}>
        <Grid item xs={6}>
          <img className={classes.img} src={img} alt={text} />
        </Grid>
        <Grid item xs={6}>
          <Typography
            color={"secondary"}
            align={"center"}
            className={classes.txtValue}
            variant={"h6"}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export const BannerValues = () => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Typography color={"primary"} className={classes.title} variant={"h4"}>
        Trabajando juntos para mantener tu salud y bienestar
      </Typography>
      <Typography
        className={classes.subtitle}
        variant={"body2"}
      >
        
      </Typography>
      <Grid justify={"center"} alignItems={"center"} container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid direction={"column"} container spacing={3}>
            <ItemValue
              classes={classes}
              text={"Compromiso"}
              img={imgCompromiso}
            />
            <ItemValue
              classes={classes}
              text={"Tecnología"}
              img={imgTecnology}
            />
            <ItemValue
              classes={classes}
              text={"Responsabilidad"}
              img={imgResponsibility}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <span className={classes.spamImage}>
            <img
              className={classes.imgValues}
              src={valuesImg}
              alt={"LaboratorioClínico"}
            />
          </span>
        </Grid>
      </Grid>
    </div>
  );
};
