import { Container } from "@material-ui/core";
import React from "react";
import { Header } from "../layout/Header";
import StepsHomeCosult from "../layout/StepsHomeCosult";

export const HomeConsultationPage = () => {
  return (
    <div>
      <Header
        titel="Consultas a Domicilio"
        subtitel="Para la comodidad de nuestros clientes contamos con el servicio de consulta a domicilio"
      />
      <Container>
        <StepsHomeCosult />
      </Container>
    </div>
  );
};
