import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#003E7A",
    paddingInline: "10vw",
    paddingBlock: "20px",
    marginBlock: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "#FFFFFF",
    "@media (max-width: 900px)": {
      fontSize: "24px",
      paddingRight: 0,
    },
  },
  subTitle: {
    color: "#FFFFFF",
  },
  line: {
    width: "150px",
    textAlign: "left",
    marginLeft: 0,
    height: "3px",
    borderColor: "#DF0A0E",
    background: "#DF0A0E",
  },
}));



export const Header = ({ titel, subtitel }) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <hr className={classes.line}></hr>
      <Typography className={classes.title} variant={"h4"}>
        {titel}
      </Typography>
      <Typography className={classes.subTitle} variant={"body1"}>
        {subtitel}
      </Typography>
    </div>
  );
};
