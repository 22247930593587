import React from "react";
import video from "../../../assets/spot_baja.mp4";
import { Button, Typography } from "@material-ui/core";
import style from "./BackgroundVideo.module.css";
import { makeStyles } from "@material-ui/core/styles";

export const BackgroundVideo = () => {
  const useStyles = makeStyles({
    body: {
      color: "#ffffff",
      fontSize: "16px",
    },
    header: {
      color: "#ffffff",
      fontSize: "34px",
      fontWeight: "bold",
      "@media (max-width: 900px)": {
        fontSize: "24px",
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <div className={style.containerVideo}>
        <video autoPlay="autoplay" loop="loop" muted className={style.myVideo}>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={style.row}>
        <div className={style.content}>
          <Typography color={"primary"} className={classes.header}>
            SU LABORATORIO DE CONFIANZA
          </Typography>

          <Typography className={classes.body}>
            Contamos con laboratorios altamente equipados, proveyendo y
            manteniendo equipos y reactivos de diagnóstico in vitro de alta
            calidad para contribuir al correcto diagnóstico nuestros pacientes.
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "black" }}
            href={"https://youtu.be/O_lFAF00lGg"}
          >
            <Button size="large" variant="contained" color="secondary">
              Ver Spot Publicitario
            </Button>
          </a>
        </div>
      </div>
    </>
  );
};
