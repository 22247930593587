import React from "react";
import { Header } from "../layout/Header";
import { BannerContact } from "../layout/BannerContact";
import { AdressesContainer } from "../layout/AdressesContainer";


export const ContactPage = () => {
  return (
    <div >
      <Header
        titel="Contáctenos"
        subtitel="¿Tiene alguna pregunta sobre una prueba, análisis o consultas para nosotros?"
      />
      <BannerContact />
      <AdressesContainer />
    </div>
  );
};
