import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";
import { Element } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  img: {
    borderRadius: "10px",
  },
}));

const Exams = ({ classes, exams }) => (
  <Container>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {exams.map((exam) => (
        <Grid key={exam} item xs={12} md={4}>
          <Grid container className={classes.root}>
            <Grid item xs={1}>
              <ChevronRight color="secondary" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">{exam}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Container>
);

const Requirements = ({ classes, requirements }) => (
  <Container>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {requirements.map((requirement) => (
        <Grid key={requirement} item xs={12} md={4}>
          <Grid container className={classes.root}>
            <Grid item xs={1}>
              <ChevronRight color="secondary" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">{requirement}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Container>
);
export const AreaSelected = ({ area }) => {
  const classes = useStyles();
  return (
    <Element id="area-decpription" name="area-decpription">
      <Typography
        className={classes.title}
        color={"primary"}
        align={"center"}
        variant={"h4"}
      >
        {area.name}
      </Typography>
      <Image
        className={classes.img}
        src={area.img}
        aspectRatio={35 / 10}
        alt={area.name}
      />
      {area.description && (
        <>
          <Typography color="primary" className={classes.title} variant={"h6"}>
            Descripción
          </Typography>
          <Typography variant={"body1"}>{area.description}</Typography>
        </>
      )}
      <Typography color="primary" className={classes.title} variant={"h6"}>
        Exámenes
      </Typography>
      <Exams classes={classes} exams={area.tests} />
      <Typography color="primary" className={classes.title} variant={"h6"}>
        Requisitos
      </Typography>
      <Requirements classes={classes} requirements={area.requirements} />
    </Element>
  );
};
