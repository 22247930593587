import React from "react";
import { BackgroundVideo } from "../layout/BackgroundVideo/BackgroundVideo";
import { makeStyles } from "@material-ui/core/styles";
import { GridCardsHome } from "../layout/GridCardsHome";
import { BannerValues } from "../layout/BannerValues";
import { AdressesContainer } from "../layout/AdressesContainer";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "calc(100vh - 93px)",
    width: "100%",
  },
}));
export const HomePage = () => {
  const classes = useStyles();

  return (
    <>
      <BackgroundVideo />
      <div className={classes.margin}>
        <GridCardsHome />
        <BannerValues />
        <AdressesContainer />
      </div>
    </>
  );
};
