import {
  AppBar,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ArrowRight } from "@material-ui/icons";
import React from "react";
import { ReactComponent as Logo } from "../../assets/Logo.svg";

const headersData = [
  {
    label: "Inicio",
    href: "/",
  },
  {
    label: "Quiénes Somos",
    href: "/quienes-somos",
  },
  {
    label: "Exámenes de Laboratorio",
    href: "/examenes-laboratorio",
  },
  {
    label: "Consulta a Domicilio",
    href: "/consulta-domicilio",
  },
  {
    label: "Resultados",
    href: "/resultados",
  },
  {
    label: "Contacto",
    href: "/contacto",
  },
];

export const Footer = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <AppBar position="static" color="primary">
        <Container maxWidth={"lg"}>
          <Toolbar>
            <Grid container direction="column" alignItems="center">
              <Grid item style={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      {...{ component: RouterLink, to: "/" }}
                      color="inherit"
                    >
                      <Logo
                        style={{ borderRadius: "10px" }}
                        width={"100%"}
                        height={"100%"}
                        as={"LaboratorioClifford"}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <List dense>
                      {headersData.map((header) => (
                        <ListItem
                          component={RouterLink}
                          to={header.href}
                          button
                          key={header.label}
                        >
                          <ListItemIcon>
                            <ArrowRight color={"secondary"} />
                          </ListItemIcon>
                          <ListItemText primary={header.label} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="inherit">
                  Copyright © 2022 Laboratorio Clifford SRL
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
