import { Container, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { AreaSelected } from "../layout/AreaSelected";
import { Header } from "../layout/Header";
import { ListAreas } from "../layout/ListAreas";
import { Areas } from "../company/Areas";

export const LaboratoryExamPage = () => {
  const [areaSelected, setAreaSelected] = useState(Areas[0]);
  return (
    <div>
      <Header
        titel="Exámenes para la detección y seguimiento"
        subtitel="Contamos con amplia variadad de exámenes clínicos a disposición de nuestro clientes"
      />
      <Container>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={3}>
            <ListAreas
              areas={Areas}
              onSelectedArea={(area) => {
                setAreaSelected(area);
              }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <AreaSelected area={areaSelected} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
