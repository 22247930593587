import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import { Mail, WhatsApp } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "calc(100vh - 93px)",
    width: "100%",
  },
  section: {
    backgroundColor: "#EEEEEE",
    paddingInline: "8vw",
    paddingBlock: "3vw",
    marginTop: "30px",
  },
  circle: {
    width: "345px",
    height: "345px",
    backgroundColor: "white",
    borderRadius: "50%",
    margin: "10px",
    "@media (max-width: 900px)": {
      width: "300px",
      height: "300px",
    },
  },
  titleCircle: {
    fontWeight: "bold",
    fontSize: "24px",
  },
}));
const ItemConcat = ({ classes, card }) => (
  <Grid className={classes.circle} item>
    <Grid
      container
      style={{ height: "100%" }}
      direction="column"
      justify="space-evenly"
      alignItems="center"
    >
      <Grid item>
        <Typography
          color="primary"
          className={classes.titleCircle}
          align="center"
        >
          {card.title}
        </Typography>
      </Grid>
      <Grid item>
        {card.items.map((item) => (
          <a
            key={item}
            rel="noreferrer"
            style={{ textDecoration: "none", color: "black" }}
            href={card.href}
            target={card.target && card.target}
          >
            <Grid container spacing={2}>
              <Grid item>{card.icon}</Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body1">{item}</Typography>
              </Grid>
            </Grid>
          </a>
        ))}
      </Grid>
      <a
        rel="noreferrer"
        style={{ textDecoration: "none", color: "black" }}
        href={card.href}
        target={card.target && card.target}
      >
        <Grid item>{card.buton}</Grid>
      </a>
    </Grid>
  </Grid>
);

const circles = [
  {
    title: "Teléfonos",
    items: ["(591) - 4 - 4359049", "(591) - 77966650"],
    icon: <PhoneIcon color={"secondary"} />,
    buton: (
      <Button color={"secondary"} variant="contained">
        Llamar Ahora
      </Button>
    ),
    href: `tel: 591 77966650`,
  },
  {
    title: "Escribenos a WhatsApp",
    items: ["(591) - 77966650", "( 591) - 70361205"],
    icon: <WhatsApp color={"secondary"} />,
    buton: (
      <Button color={"secondary"} variant="contained">
        Escríbenos
      </Button>
    ),
    href: `https://wa.me/59177966650`,
    target: "_blank",
  },
  {
    title: "Correo Electronico",
    items: ["laboratoriclifford@gmail.com"],
    icon: <Mail color={"secondary"} />,
    buton: (
      <Button color={"secondary"} variant="contained">
        Enviar Correo
      </Button>
    ),
    href: `mailto: laboratorioclifford@gmail.com`,
  },
];
export const BannerContact = () => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Grid container justify={"center"} alignItems="center" spacing={4}>
        {circles.map((circle) => (
          <ItemConcat classes={classes} card={circle} />
        ))}
      </Grid>
    </div>
  );
};
