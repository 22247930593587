import React from "react";
import CardInfo from "../layout/CardInfo";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import microscope from "../../assets/microscope.png";
import hospital from "../../assets/hospital.png";
import phoneCall from "../../assets/phone-call.png";

const infoCards = [
  {
    image: microscope,
    titel: "Exámenes de laboratorio",
    description: "Análisis clínicos con la más alta tecnología.",
    button: "Más",
    href: "/examenes-laboratorio",
  },
  {
    image: hospital,
    titel: "Nuestra empresa",
    description: "Conoce un poco mas de nuestros 21 años de experiencia.",
    button: "Más",
    href: "/quienes-somos",
  },
  {
    image: phoneCall,
    titel: "Contáctanos",
    description: "Obtén más información para contáctarnos.",
    button: "Más",
    href: "/contacto",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    justifyContent: "center",
    paddingTop: "50px",
    display: "flex",
  },
  grid: {
    paddingLeft: "8vw",
    paddingRight: "8vw",
    justifyContent: "center",
  },
  margin: {
    marginTop: "calc(100vh - 93px)",
    width: "100%",
  },
}));
export const GridCardsHome = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.grid}>
        {infoCards.map((card) => (
          <Grid
            key={card.titel}
            className={classes.gridItem}
            item
            xs={12}
            sm={6}
            md={4}
          >
            <CardInfo card={card} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
