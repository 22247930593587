import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CardHeader, Grid } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import Image from "material-ui-image";
import imgCentral from "../../assets/central.png"
import imgSucursal1 from "../../assets/sucursal1.png"
import imgSucursal2 from "../../assets/sucursal2.png"
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 150,
  },
  section: {
    paddingInline: "8vw",
    paddingBlock: "3vw",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "30px",
  },
  titleCard: {
    fontWeight: "bold",
    textAlign: "center",
  },
  cardActions: {
    justifyContent: "center",
  },
  img: {
    borderRadius: "10px",
  },
});
const AdressCard = ({ branch }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.titleCard}
        title={
          <Typography
            className={classes.titleCard}
            color={"primary"}
            variant="h5"
          >
            {branch.name}
          </Typography>
        }
      />

      <Image
        className={classes.img}
        src={branch.img}
        aspectRatio={5 / 4}
        alt={branch.name}
      />
      <CardContent>
        {branch.numbers.map((number) => (
          <a
            key={number}
            rel="noreferrer"
            style={{ textDecoration: "none", color: "black" }}
            href={`tel:${number}`}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <PhoneIcon color={"secondary"} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{number}</Typography>
              </Grid>
            </Grid>
          </a>
        ))}
        <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "black" }}
          href={`https://www.google.com/maps/search/?api=1&query=${branch.lat},${branch.lon}&query_place_id=${branch.placeID}`}
        >
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <RoomIcon color={"secondary"} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{branch.address}</Typography>
            </Grid>
          </Grid>
        </a>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${branch.lat},${branch.lon}&query_place_id=${branch.placeID}`}
          variant="contained"
          color="secondary"
        >
          Ver en Mapa
        </Button>
      </CardActions>
    </Card>
  );
};

export const AdressesContainer = () => {
  const classes = useStyles();
  const branches = [
    {
      name: "Quillacollo Central",
      numbers: ["(4) 4391153", "70361205"],
      address: "Calle Carmela Serruto acera noerte casi Suarez Miranda",
      lat: -17.3927258520586,
      lon: -66.28118317343967,
      placeID: "ChIJdXnbAHcL45MR5ZP5ecNKTOE",
      img: imgCentral,
    },
    {
      name: "Quillacollo Sucursal 1",
      numbers: ["(4) 4369916", "77966650"],
      address:
        "Calle Suarez Miranda acera oeste #0225 entre C. Serruto y Luis Uria",
      lat: -17.392384703470594,
      lon: -66.28089078243003,
      placeID: "ChIJ8VlUV7UL45MRVlMkMmmRDdU",
      img: imgSucursal1,
    },
    {
      name: "Vinto Sucursal 2",
      numbers: ["(4) 4359049", "77966650"],
      address: "Av. Albina Patiño (Lado Policia) C. Sacarias Valenzuela #1105",
      lat: -17.39594371633361,
      lon: -66.31317131164643,
      placeID: "ChIJ5SxYMOwL45MRgXIMvoBsy28",
      img: imgSucursal2,
    },
  ];
  return (
    <div className={classes.section}>
      <Typography color={"primary"} className={classes.title} variant={"h4"}>
        Encuentranos en:
      </Typography>
      <Grid container justify={"center"} spacing={3}>
        {branches.map((branch) => (
          <Grid key={branch.name} item>
            <AdressCard branch={branch} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
