import React from "react";
import esLocale from "date-fns/locale/es";
import {
  Typography,
  Paper,
  Grid,
  Button,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { TextField, Radio } from "final-form-material-ui";
import { Form, Field } from "react-final-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Requerido";
  }
  if (!values.lastName) {
    errors.lastName = "Requerido";
  }
  if (!values.number) {
    errors.number = "Requerido";
  }
  if (!values.dateBirth) {
    errors.dateBirth = "Requerido";
  }

  return errors;
};

function DatePickerWrapper(props) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <KeyboardDatePicker
      {...rest}
      disableFuture
      name={name}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      inputProps={restInput}
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      onChange={onChange}
      value={value === "" ? null : value}
    />
  );
}

export const Step1 = ({ next }) => {
  const onSubmit = (values) => {
    //localStorage.setItem("person", JSON.stringify(values));
    next(values);
  };
  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Datos Personales
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{ gender: "male" }}
        validate={validate}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16 }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    required
                    name="name"
                    component={TextField}
                    type="text"
                    label="Nombre(s)"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    required
                    name="lastName"
                    component={TextField}
                    type="text"
                    label="Apellidos"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="number"
                    fullWidth
                    required
                    component={TextField}
                    type="text"
                    label="Teléfono"
                    min={0}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    fullWidth
                    component={TextField}
                    type="email"
                    label="Correo Electronico"
                  />
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="dateBirth"
                      component={DatePickerWrapper}
                      fullWidth
                      required
                      margin="normal"
                      label="Fecha de Nacimiento(dd/mm/aaaa)"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Género</FormLabel>
                    <RadioGroup row>
                      <FormControlLabel
                        label="Masculino"
                        control={
                          <Field
                            name="gender"
                            component={Radio}
                            type="radio"
                            value="male"
                          />
                        }
                      />
                      <FormControlLabel
                        label="Femenino"
                        control={
                          <Field
                            name="gender"
                            component={Radio}
                            type="radio"
                            value="female"
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="addres"
                    component={TextField}
                    multiline
                    label="Dirección"
                  />
                </Grid>
              </Grid>
            </Paper>
            <div>
              <Grid item style={{ marginTop: 16 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button variant="outlined" color="primary" disabled={true}>
                      Atras
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Siguiente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
