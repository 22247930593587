import React from "react";
import Button from "@material-ui/core/Button";
import { Header } from "../layout/Header";
import {
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import imgHistory1 from "../../assets/history.png";
import imgMission from "../../assets/mission.png";
import imgVision from "../../assets/vision.png";
import Image from "material-ui-image";
import { Team as TeamMembers } from "../company/Team";
import { Element, Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    backgroundColor: "#EEEEEE",
    borderRadius: "10px",
  },
  button: {
    fontWeight: "bold",
    width: "170px",
  },
  title: {
    fontWeight: "bold",
    paddingBlock: "20px",
    "@media (max-width: 900px)": {
      fontSize: "24px",
      paddingRight: 0,
    },
  },
  titleName: {
    fontWeight: "bold",
  },
  card: {
    maxWidth: 300,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  mediaCard: {
    width: "100%",
  },
  description: {
    fontSize: "12px",
  },
}));

const ListButtons = ({ classes }) => {
  return (
    <Container>
      <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
        <div style={{ display: "inline-block" }}>
          <Grid className={classes.buttonGroup} container justify={"center"}>
            <Grid item>
              <Link
                activeClass="active"
                className="history"
                to="history"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button color="secondary" className={classes.button}>
                  Historia
                </Button>
              </Link>
            </Grid>
            <Grid>
              <Link
                activeClass="active"
                className="mission"
                to="mission"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button color="secondary" className={classes.button}>
                  Misión
                </Button>
              </Link>
            </Grid>
            <Grid>
              <Link
                activeClass="active"
                className="vision"
                to="vision"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button color="secondary" className={classes.button}>
                  Visión
                </Button>
              </Link>
            </Grid>
            <Grid>
              <Link
                activeClass="active"
                className="team"
                to="team"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button color="secondary" className={classes.button}>
                  Nuestro Equipo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

const History = ({ classes }) => {
  return (
    <Container>
      <div style={{ marginBlock: "30px" }}>
        <Typography
          align={"center"}
          color={"primary"}
          className={classes.title}
          variant={"h4"}
        >
          Historia
        </Typography>
        <div style={{ marginInline: "5%" }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <Image
                src={imgHistory1}
                aspectRatio={16 / 9}
                alt={"Historia Laboratorio Cliford"}
                imageStyle={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid style={{ textAlign: "center" }} item xs={12} md={6}>
              <Typography variant={"h6"}>
                El laboratorio tiene 15 años de experiencia a cargo del doctor
                Clifford Vargas con prácticas profesionales desde el año 2000 a
                la fecha con especialidad de bacteriología clínica y la doctora
                Paola Escalera con especialidad de inmunología celular y
                molecular.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

const Mission = ({ classes }) => {
  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      <Container>
        <div style={{ marginBlock: "30px" }}>
          <div style={{ marginInline: "5%" }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} md={4}>
                <Image
                  color={"transparent"}
                  src={imgMission}
                  aspectRatio={1 / 1}
                  alt={"Misión Laboratorio Cliford"}
                  imageStyle={{ width: "auto", height: "100%" }}
                />
              </Grid>
              <Grid style={{ textAlign: "center" }} item xs={12} md={8}>
                <Typography
                  color={"primary"}
                  className={classes.title}
                  variant={"h4"}
                >
                  Misión
                </Typography>
                <Typography variant={"h6"}>
                  Realizar exámenes de laboratorio clínico, confiables,
                  confidenciales y oportunos, mediante el uso de excelente
                  tecnología y el respaldo de un recurso humano capacitado,
                  motivado y comprometido, guiado por procesos con calidad
                  estandarizados, con principios y valores, logrando así la
                  satisfacción de los pacientes.
                </Typography>
                <Typography variant={"h6"}>
                  Brindar servicios clínicos y exámenes de laboratorio de la más
                  alta calidad, rápidos y costo efectivos mediante el uso de
                  excelente tecnología que impacten positivamente y con el
                  respaldo de un recurso humano con profesionalismo, compromiso
                  y vocación de servicio, garantizando así la satisfacción total
                  del paciente.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

const Vision = ({ classes }) => {
  return (
    <Container>
      <div style={{ marginBlock: "30px" }}>
        <div style={{ marginInline: "5%" }}>
          <Grid
            container
            direction="row-reverse"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              <Image
                color={"transparent"}
                src={imgVision}
                aspectRatio={1 / 1}
                alt={"Visión Laboratorio Cliford"}
                imageStyle={{ width: "auto", height: "100%" }}
              />
            </Grid>
            <Grid style={{ textAlign: "center" }} item xs={12} md={8}>
              <Typography
                color={"primary"}
                className={classes.title}
                variant={"h4"}
              >
                Visión
              </Typography>
              <Typography variant={"h6"}>
                Ser el mejor laboratorio clínico, proveyendo servicios de
                calidad para mejorar la salud de todos nuestros pacientes y
                aportar a su calidad de vida.
              </Typography>
              <Typography variant={"h6"}>
                Ser un Laboratorio Clínico acreditado, desarrollando un sistema
                de gestión de calidad que se traduzca en mejores servicios para
                nuestros pacientes y así ser reconocidos por ellos como Empresa
                de personas líder en el àrea.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

const MemberTeam = ({ classes, member }) => {
  return (
    <Grid className={classes.card} item xs={12} xl={6}>
      <CardMedia>
        <Image
          color={"transparent"}
          src={member.photo}
          aspectRatio={1 / 1}
          alt={member.name}
          imageStyle={{ width: "auto", height: "100%" }}
        />
      </CardMedia>
      <CardContent>
        <Typography align={"center"} variant="h5" className={classes.titleName}>
          {member.name}
        </Typography>

        <Typography
          className={classes.description}
          align={"center"}
          variant="body1"
        >
          <li>{member.position}</li>
        </Typography>
        {member.diplomado && (
          <Typography
            className={classes.description}
            align={"center"}
            variant="body1"
          >
            <li>{member.diplomado}</li>
          </Typography>
        )}
      </CardContent>
    </Grid>
  );
};

const Team = ({ classes }) => {
  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      <Container>
        <div style={{ marginBlock: "30px" }}>
          <div style={{ marginInline: "5%" }}>
            <Typography
              color={"primary"}
              className={classes.title}
              variant={"h4"}
              align={"center"}
            >
              Nuestro Equipo
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="flex-start"
              spacing={5}
            >
              {TeamMembers.map((member) => (
                <MemberTeam
                  key={member.name}
                  classes={classes}
                  member={member}
                />
              ))}
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export const AboutUsPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Header
        titel="Conoce un poco más del mejor Laboratorio"
        subtitel="Vea por qué somos uno de los laboratorios de Bolivia, buscando la continua mejora para nuestros clientes..."
      />
      <ListButtons classes={classes} />
      <Element id="history" name="History">
        <History classes={classes} />
      </Element>
      <Element id="mission" name="Mission">
        <Mission classes={classes} />
      </Element>
      <Element id="vision" name="Vision">
        <Vision classes={classes} />
      </Element>
      <Element id="team" name="Team">
        <Team classes={classes} />
      </Element>
    </div>
  );
};
