import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Step1 } from "./steps/Step1";
import { Typography, Button, Container } from "@material-ui/core";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Complete los datos personales",
    "Seleccione fecha y hora para la consulta",
    "Confirmar Solicitud de Consulta",
  ];
}

export default function StepsHomeCosult() {
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Step1 next={(person) => handleStep1(person)} />;
      case 1:
        return (
          <Step2
            next={(dateConsult, timeConsult, note) =>
              handleStep2(dateConsult, timeConsult, note)
            }
            back={handleBack}
          />
        );
      case 2:
        return (
          <Step3
            back={handleBack}
            personalInfo={personalInfo}
            consultInfo={consultInfo}
            next={() => handleStep3()}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [personalInfo, setPersonalInfo] = React.useState(null);
  const [consultInfo, setConsultInfo] = React.useState(null);

  const steps = getSteps();
  const handleStep1 = (person) => {
    setPersonalInfo(person);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep2 = (dateConsult, timeConsult, note) => {
    var consult = {
      date: new Date(
        dateConsult.getFullYear(),
        dateConsult.getMonth(),
        dateConsult.getDate(),
        timeConsult.getHours(),
        timeConsult.getMinutes(),
        timeConsult.getSeconds(),
        timeConsult.getMilliseconds()
      ),
      note: note,
    };
    setConsultInfo(consult);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep3 = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" color="primary" align="center">
        Para solicitar una consulta a domicilio complete los 3 sencillos pasos
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography
              variant="h4"
              align="center"
              color="primary"
              gutterBottom
            >
              ¡Solicitud enviada con éxito!
            </Typography>
            <Container>
              <Typography variant="h6">
                Su solicitud se guardo correctamente, nos pondremos en contacto
                a la brevedad posible para confirmar el día y la fecha. Gracias.
              </Typography>
              <Button  onClick={handleReset} variant="contained" color="primary">
                Nueva Solicitud
              </Button>
            </Container>
          </div>
        ) : (
          <div>{getStepContent(activeStep)}</div>
        )}
      </div>
    </div>
  );
}
