import React from "react";
import esLocale from "date-fns/locale/es";
import { Typography, Paper, Grid, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "final-form-material-ui";
import { Form, Field } from "react-final-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const validate = (values) => {
  const errors = {};
  if (!values.dateConsult) {
    errors.dateConsult = "Requerido";
  }
  if (String(values.dateConsult) === "Invalid Date") {
    errors.dateConsult = "Fecha Invalida";
  }
  if (String(values.timeConsult) === "Invalid Date") {
    errors.timeConsult = "Hora Invalida";
  }
  if (!values.timeConsult) {
    errors.timeConsult = "Requerido";
  }
  return errors;
};

function DatePickerWrapper(props) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <KeyboardDatePicker
      {...rest}
      disablePast
      name={name}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      inputProps={restInput}
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      onChange={onChange}
      value={value === "" ? null : value}
    />
  );
}

function TimePickerWrapper(props) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <KeyboardTimePicker
      {...rest}
      disableFuture
      name={name}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      inputProps={restInput}
      placeholder="08:00 AM"
      mask="__:__ _M"
      onChange={onChange}
      value={value === "" ? null : value}
    />
  );
}

export const Step2 = ({ next, back }) => {
  const onSubmit = (values) => {
    next(values.dateConsult, values.timeConsult, values.note);
  };
  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Fecha y Hora de Consulta
      </Typography>
      <Form onSubmit={onSubmit} initialValues={{}} validate={validate}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Paper style={{ padding: 16 }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="dateConsult"
                      component={DatePickerWrapper}
                      fullWidth
                      required
                      margin="normal"
                      label="Fecha de consulta (dd/mm/aaaa)"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="timeConsult"
                      component={TimePickerWrapper}
                      fullWidth
                      required
                      margin="normal"
                      label="Hora de consulta (mm/hh)"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="note"
                    component={TextField}
                    multiline
                    label="Nota para laboratorio (opcional)"
                  />
                </Grid>
              </Grid>
            </Paper>
            <div>
              <Grid item style={{ marginTop: 16 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={back}>
                      Atras
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Siguiente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
