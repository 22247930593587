import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Divider,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { useLocation } from "react-router-dom";

const headersData = [
  {
    label: "Inicio",
    href: "/",
  },
  {
    label: "Quiénes Somos",
    href: "/quienes-somos",
  },
  {
    label: "Exámenes de Laboratorio",
    href: "/examenes-laboratorio",
  },
  {
    label: "Consulta a Domicilio",
    href: "/consulta-domicilio",
  },
  {
    label: "Resultados",
    href: "/resultados",
  },
  {
    label: "Contacto",
    href: "/contacto",
  },
];

const useStyles = makeStyles(() => ({
  header: {
    paddingRight: "50px",
    paddingLeft: "50px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 550,
    size: "16px",
    marginLeft: "16px",
    color: "white",
  },
  menuTitel: {
    color: "secondary",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 550,
    size: "16px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export const NavBar = () => {
  const { header, menuButton, toolbar, drawerContainer, menuTitel } =
    useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1216
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{femmecubatorLogo}</div>
        <IconButton
          {...{
            edge: "end",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <GetDrawerChoices close={handleDrawerClose} />
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  const GetDrawerChoices = ({ close }) => {
    return (
      <>
        <Typography
          color="primary"
          className={menuTitel}
          variant="h5"
          gutterBottom
        >
          Menú
        </Typography>
        {headersData.map(({ label, href }) => {
          return (
            <div key={label}>
              <Link
                {...{
                  component: RouterLink,
                  to: href,
                  color: "inherit",
                  style: { textDecoration: "none" },
                  key: label,
                }}
                onClick={() => close()}
              >
                <MenuItem>{label}</MenuItem>
              </Link>
              <Divider />
            </div>
          );
        })}
      </>
    );
  };

  const femmecubatorLogo = (
    <Button {...{ component: RouterLink, to: "/" }} color="inherit">
      <Logo
        width={"100%"}
        height={"100%"}
        style={{ borderRadius: "10px" }}
        as={"LaboratorioClifford"}
      />
    </Button>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            to: href,
            component: RouterLink,
            color: "secondary",
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };
  const location = useLocation();

  return (
    <AppBar
      style={{
        background:
          location.pathname !== "/" ? "#003E7A" : "rgba(32, 32, 32, 0)",
      }}
      position={"relative"}
      className={header}
    >
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
};
