import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-scroll";
import { CheckCircle } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Area = ({ area, onSelectedArea }) => {
  const handleClick = () => {
    onSelectedArea(area);
  };
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <CheckCircle color="primary" />
      </ListItemIcon>
      <ListItemText primary={area.name} />
    </ListItem>
  );
};

export const ListAreas = ({ areas, onSelectedArea }) => {
  const classes = useStyles();

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Seleccione el exámene clinico
          </ListSubheader>
        }
        className={classes.root}
      >
        {areas.map((area) => (
          <Link
            activeClass="active"
            className="area-decpription"
            to="area-decpription"
            spy={true}
            smooth={true}
            duration={500}
            key={area.name}
          >
            <Area onSelectedArea={onSelectedArea} area={area} />
          </Link>
        ))}
      </List>
    </div>
  );
};
