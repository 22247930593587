import imgHematologia from "../../assets/areas/hematologia.png";
import imgCovid from "../../assets/areas/imgCovid.png";
import imgPerfilDeHierro from "../../assets/areas/imgPerfilDeHierro.png";
import imgQuimicaSanguinea from "../../assets/areas/imgQuimicaSanguinea.png";
import imgPerfilCardico from "../../assets/areas/imgPerfilCardico.png";
import imgPerfilHepatico from "../../assets/areas/imgPerfilHepatico.png";
import imgPerfilLipidico from "../../assets/areas/imgPerfilLipidico.png";
import imgHCGB from "../../assets/areas/imgHCG-B.png";
import imgElectrolitos from "../../assets/areas/imgElectrolitos.png";
import imgOrina from "../../assets/areas/imgOrina.png";
import imgHecesFecales from "../../assets/areas/imgHecesFecales.png";
import imgSerologia from "../../assets/areas/imgSerologia.png";
import imgInmunologia from "../../assets/areas/imgInmunologia.png";
import imgAutoinmunes from "../../assets/areas/imgAutoinmunes.png";
import imgTumorales from "../../assets/areas/imgTumorales.png";
import imgHormonas from "../../assets/areas/imgHormonas.png";
import imgLiquidosOrganicos from "../../assets/areas/imgLiquidosOrganicos.png";
import imgBacteriologia from "../../assets/areas/imgBacteriologia.png";
import imgCultivo from "../../assets/areas/imgCultivo.png";

export const Areas = [
  {
    name: "Hemalología",
    description:
      "La hematología es la especialidad médica que se ocupa del estudio, diagnóstico, tratamiento y prevención de las enfermedades de la sangre y los órganos que participan en su producción, como son la médula ósea, el bazo o los ganglios, entre otros",
    img: imgHematologia,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "Hemograma Automatizado",
      "VES",
      "Hematocrito (Hto) - (Hb)",
      "Índices Hemáticos",
      "Recuento de Plaquetas",
      "Grupo Sanguíneo",
      "Coombs Directo",
      "C. Indirecto",
      "Células LE",
      "T. de Coagulación",
      "T. Sangria",
      "T. Protrombina",
      "T.P.T.a",
      "Retracción del Coágulo",
      "Fragilidad Capilar",
      "Gota Gruesa (Hemoparásitos)",
      "Reguento de Reticulocitos",
      "Recuento de Eosinófilos",
      "Fifrinógeno",
      "Dimero D (Cuantitativo)",
    ],
  },
  {
    name: "Covid 19",
    description:
      "El coronavirus es un grupo de virus que causan enfermedades que van desde el resfriado común hasta enfermedades más graves como neumonía, síndrome respiratorio de Oriente Medio (MERS) y síndrome respiratorio agudo grave (SARS). Cabe destacar que la cepa de coronavirus (2019-nCoV) que ha causado el brote en China es nueva y no se conocía previamente.",
    img: imgCovid,
    requirements: ["Protocolo de bioseguridad"],
    tests: ["PCR", "IgG", "IgM", "Antigeno Nasal"],
  },
  {
    name: "Perfil de Hierro",
    description:
      "El perfil férrico incluye varios marcadores que nos sirven para identificar que la anemia es debida al déficit de Hierro, elemento imprescindible para la formación de Hemoglobina.",

    img: imgPerfilDeHierro,
    requirements: ["Ayuno de 6 horas"],

    tests: [
      "Ferritina (ELISA)",
      "% de Saturación Fe",
      "TIBC",
      "Transferrina",
      "Hierro Sérico",
    ],
  },
  {
    name: "Química Sanguínea",
    description:
      "Los exámenes de química sanguínea miden los niveles de ciertas sustancias en la sangre el cual suministra información al médico por medio de una serie de pruebas acerca del metabolismo en el cuerpo humano y el funcionamiento de ciertos órganos como el hígado y los riñones.",
    img: imgQuimicaSanguinea,
    requirements: ["Ayuno de 6 horas"],

    tests: [
      "Glucosa (Ayuno / post Prandial",
      "Tolerancia a la Glucosa X2",
      "Hb. Glicosilada",
      "Urea",
      "Creatinina",
      "Ac. Urico",
      "Proteínas Totales",
      "Albumina",
      "Globuliba",
      "Relación A/G",
      "ADA (TB)",
      "a-Amilasa",
      "Lipasa",
      "Colinesterasa",
      "Gamma Glutamil Transferasa",
    ],
  },
  {
    name: "Perfil Cardíaco",
    description:
      "El perfil cardíaco es un análisis clínico que puede detectar un posible infarto u otras enfermedades cardíacas.",
    img: imgPerfilCardico,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "CPK",
      "CPK - MB",
      "Troponina (Cuantitativo)",
      "Lactado Deshidrogenasa",
      "GOT.",
    ],
  },
  {
    name: "Perfil Hepático",
    description:
      "El perfil Hepático es un examen de sangre en el que se mide la presencia de algunas enzimas, proteínas y bilirrubina en sangre, con el objetivo de determinar si existe alguna alteración en el hígado.",
    img: imgPerfilHepatico,
    requirements: ["Ayuno de 6 horas"],

    tests: ["Bilirrubinas", "Fosfatasa Alcalina", "GOT/GPT"],
  },
  {
    name: "Perfil Lípidico",
    requirements: ["Ayuno de 6 horas"],

    description:
      "El perfil lipídico es un grupo de exámenes de laboratorio que los médicos suelen solicitar para determinar los niveles de lípidos en la sangre, como el colesterol y los triglicéridos, cuya alteración está relacionada con las enfermedades cardiovasculares.",
    img: imgPerfilLipidico,
    tests: [
      "Col. Total",
      "Trigliceridos",
      "HDL - Col.",
      "LDL - Col",
      "VLDL - Col.",
      "Riesgo Cardiáco",
    ],
  },
  {
    name: "HCG-B",
    description: "Prueba de embarazo",
    requirements: ["Ayuno de 6 horas"],
    img: imgHCGB,
    tests: [
      "HCG - B en Orina",
      "HCG - B en Sangre",
      "HCG - B Cuantitativo (ELISA)",
    ],
  },
  {
    name: "Electrolitos",
    requirements: ["Ayuno de 6 horas"],
    description:
      "Los electrolitos son minerales en el cuerpo que tienen una carga eléctrica. Se encuentran en la sangre, la orina, tejidos y otros líquidos del cuerpo.",
    img: imgElectrolitos,
    tests: ["Sodio", "Potasio", "Cloro", "Fósforo", "Magnecio", "Calcio"],
  },
  {
    name: "Orina",
    description:
      "A menudo se realiza para chequear si hay una infección de las vías urinarias, problemas renales o diabetes. Usted también puede someterse a uno durante un chequeo médico, si es ingresado al hospital, antes de una cirugía o si está embarazada.",
    img: imgOrina,
    requirements: ["Primera orina de la mañana"],
    tests: [
      "Parcial de Orina",
      "Proteina de Bencen-Jones",
      "Calciuria",
      "Amilasuria",
      "BK en Orina",
      "Dep. Creatinina",
      "Microalbuminuria",
    ],
  },
  {
    name: "Heces Fecales",
    description:
      "El análisis de heces es una serie de pruebas que se realizan en una muestra de heces (materia fecal) para ayudar a diagnosticar ciertas afecciones que afectan el tubo digestivo.",
    img: imgHecesFecales,
    requirements: ["Muestra al azar"],
    tests: [
      "Comproparasitológico",
      "Comproparasitológico Seriado X3",
      "Comproparasitológico Concentrado",
      "Sangre Oculta",
      "Citología Moco Fecal",
      "Rotavirus",
      "Grasa",
      "Test de Graham - Oxiuros",
      "Ameba - (ELISA)",
      "Giardia - (ELISA)",
      "Helicobacter Pilory - (ELISA)",
      "pH - Azucares reductores (LACTOSA)",
    ],
  },
  {
    name: "Serología",
    description:
      "La serología es el estudio científico del suero y otros fluidos corporales. En la práctica, el término generalmente se refiere a la identificación diagnóstica de anticuerpos en el suero.",
    img: imgSerologia,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "Reacción Widal",
      "F. Reumatoide",
      'P"C"R (Cuantitativo)',
      "ASTO",
      "Chagas HAI",
      "P.R. Sars - Cov2 (Covid-19) IgM - IgG",
      "Chagas HAI",
    ],
  },
  {
    name: "Inmunología (Elisa) - (Clia)",
    description:
      "La inmunología es una rama amplia de las ciencias biomédicas que se ocupa del estudio del sistema inmunitario, entendiendo como tal al conjunto de órganos, tejidos y células que, en los vertebrados, tienen como función reconocer elementos ajenos dando una respuesta (respuesta inmunitaria).",
    img: imgInmunologia,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "Chagas",
      "Toxoplasmosis",
      "Cisticercosis",
      "Clamydia",
      "Hepes 1-2",
      "Helicobacter Pylori",
      "Hepatitis A",
      "Hepatitis B (Hbs)",
      "Hepatitis B (Hbc)",
      "Hepatitis C",
      "TORCH",
      "IgE",
      "Citomegalovirus",
      "Mononucleosis",
      "Hiv 1-2",
    ],
  },
  {
    name: "Autoinmunes (ELISA)",
    img: imgAutoinmunes,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "ANA",
      "ASMA",
      "ANCA-C",
      "Complemeto C3",
      "AMA",
      "ANCA-P",
      "ANTI CCP",
      "C4",
      "ANTI DNA",
      "ANTI DNP",
      "PERFIL ENA",
    ],
  },
  {
    name: "Marcadores Tumorales (Elisa)-(Clia)",
    img: imgTumorales,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "PSA",
      "CEA",
      "CA - 125",
      "PSA Libre",
      "a-Foto Proteina",
      "CA-19 9",
      "CA-15 3",
    ],
  },
  {
    name: "Hormonas (Elisa)-(Clia)",
    img: imgHormonas,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "T3",
      "T4",
      "T4 Libre",
      "TSH-ultra",
      "Estradiol",
      "LH",
      "FSS",
      "Progesterona",
      "Prolactina",
      "Testosterona",
      "Insulina Basal",
      "Hormona de Crecimiento",
      "Insulina Post Estimulo",
      "Cortisol",
      "AM",
      "PM",
      "PTH",
    ],
  },
  {
    name: "Liquidos Organicos",
    img: imgLiquidosOrganicos,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "Espermatograma",
      "L.C.R.",
      "Liquido Ascítico",
      "Liquido Pleural",
      "Liquido Sinovial",
      "Pesquiza de esperma",
    ],
  },
  {
    name: "Bacteriología",
    img: imgBacteriologia,
    requirements: ["Ayuno de 6 horas"],
    tests: [
      "Tinción de Gram",
      "Tinción de Ziehi Neelsen X3",
      "Secreación Vaginal en Fresco",
      "Micológico Directo",
    ],
  },
  {
    name: "Cultivo y Antibiograma(Germenes Comunes)",
    description:
      "Los gérmenes que crezcan en el cultivo serán sometidos a pruebas frente a una variedad de medicamentos para determinar cuál es el más eficaz (antibiograma). Por ejemplo, en el caso de una infección causada por bacterias, se realizarán pruebas con varios tipos de antibióticos.",
    img: imgCultivo,
    requirements: [
      "Ayuno de 6 horas",
      "Orina - Primera Oriana de la mañana de media micción",
      "Exudado Faringio – No cepillarse la boca, no enjuague bucal",
    ],
    tests: [
      "Urocultico",
      "Exudado Faringeo",
      "Secreción Vaginal",
      "Secreción Uretal",
      "Herida Infectada",
      "Cultivo para Hongos",
      "Coprocultivo",
      "Esputo",
      "Hemocultivo X3",
      "Tejido Quirúrgico",
      "Líq. PEritoneal",
      "Semen",
    ],
  },
];
