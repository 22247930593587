import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Header } from '../layout/Header'
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import { Field, Form } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { client } from '../../utils/client'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: 16
  },
  title: {
    '@media (max-width: 900px)': {
      fontSize: '20px'
    }
  },
  grid: {
    marginTop: 10
  }
}))

const ResultsPage = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)

  const validate = values => {
    const errors = {}
    if (!values.id) {
      errors.id = 'El Nº de consulta es requerido'
    }
    if (!values.password) {
      errors.password = 'La contraseña es resquerida'
    }
    return errors
  }

  const onSubmit = async values => {
    setMessage(false)
    try {
      setLoading(true)
      const { data } = await client.get('/client/results', {
        responseType: 'blob',
        headers: { ...values }
      })
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      setLoading(false)
    } catch (error) {
      const blobError = await error?.response?.data?.text()
      const errorJSON = JSON.parse(blobError || null)
      setMessage({
        severity: 'error',
        message: errorJSON?.message || 'Algo salio mal'
      })

      setLoading(false)
    }
  }

  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')
  const password = params.get('password')

  return (
    <div>
      <Header
        titel='Resultados'
        subtitel='¡Ahorre tiempo y consulte sus resultados desde la web, en cualquier momento y en cualquier lugar!'
      />
      <Container>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Typography
              variant='h5'
              color='primary'
              align='center'
              className={classes.title}
            >
              Para ver sus resultados ingrese la siguiente información que
              encontrara en el recibo de su consulta.
            </Typography>
            <Form
              onSubmit={onSubmit}
              initialValues={{ id, password }}
              validate={validate}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={3}
                    className={classes.grid}
                  >
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        required
                        name='id'
                        component={TextField}
                        type='number'
                        variant='outlined'
                        label='Nº de Consulta'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        required
                        name='password'
                        component={TextField}
                        type='password'
                        variant='outlined'
                        label='Contraseña'
                      />
                    </Grid>
                    {loading && <CircularProgress />}
                    {message && (
                      <Alert severity={message.severity}>
                        {message.message}
                      </Alert>
                    )}

                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={loading}
                      >
                        Ver Resultados
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </Paper>
        </div>
      </Container>
    </div>
  )
}

export default ResultsPage
