import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "300px",
  },
  media: {
    height: "300px",
    width: "100%",
    backgroundColor: "#EEEEEE",
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    height: "70px",
  },
  titel: {
    fontWeight: "bold",
  },
});

export default function CardInfo({ card }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={card.href}>
        <CardMedia
          fill="red"
          stroke="green"
          className={classes.media}
          image={card.image}
          title={card.titel}
          src={card.titel}
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h6" className={classes.titel}>
            {card.titel}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {card.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.button}>
        <Button
          size="small"
          component={RouterLink}
          to={card.href}
          color="secondary"
        >
          {card.button}
        </Button>
      </CardActions>
    </Card>
  );
}
